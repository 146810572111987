<template>
  <div>
    <a-carousel v-if="list.length"
                arrows effect="fade" dotPosition="top"
                class="gift-carousel" ref="carousel"
                :beforeChange="markCurrentSelected">
      <div slot="prevArrow" class="custom-slick-arrow left">
        <a-icon type="left-circle"/>
      </div>
      <div slot="nextArrow" class="custom-slick-arrow right">
        <a-icon type="right-circle"/>
      </div>

      <div class="gift" v-for="(g, i) in list" v-bind:key="i">
        <a-row class="img-wrapper" type="flex" justify="center" align="middle">
          <img :src="g.src" alt="">
        </a-row>
        <div class="gift-title">{{ g.title }}</div>
      </div>

      <!--      <div class="gift">-->
      <!--        <a-row class="img-wrapper" type="flex" justify="center" align="middle">-->
      <!--          <img src="./sample-Belkin_Boost_Charger.jpg" alt="">-->
      <!--        </a-row>-->
      <!--        <div class="gift-title">Belkin Boost Charger</div>-->
      <!--      </div>-->
      <!--      <div class="gift">-->
      <!--        <a-row class="img-wrapper" type="flex" justify="center" align="middle">-->
      <!--          <img src="./sample-Nintendo_Switch_Car_Charger.jpg" alt="">-->
      <!--        </a-row>-->
      <!--        <div class="gift-title">Nintendo Switch Car Charger</div>-->
      <!--      </div>-->
      <!--      <div class="gift">-->
      <!--        <a-row class="img-wrapper" type="flex" justify="center" align="middle">-->
      <!--          <img src="./sample-SOL_REPUBLIC_Relay.jpg" alt="">-->
      <!--        </a-row>-->
      <!--        <div class="gift-title">SOL REPUBLIC Relay</div>-->
      <!--      </div>-->

    </a-carousel>
    <p v-else style="text-align: center;font-size: 18px;font-weight: bold">Oops, no gift is available right now.</p>
  </div>
</template>

<script>
export default {
  name: "GiftSelector",
  props: {
    list: {
      default: () => [],
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      // current: null,
    }
  },
  // watch: {
  //   current: function () {
  //     // console.log('cur selected is changed to ' + this.current);
  //     this.goTo(this.current);
  //   },
  // },
  methods: {
    /**
     * 选择绑定 beforeChange 事件，避免需要等待动画完成才知道当前展示的是哪一个。
     * @param from
     * @param to
     */
    // eslint-disable-next-line
    markCurrentSelected: function (from, to) {
      // console.log(from);
      // console.log(to);
      this.current = to;
      this.$emit('change', to);
    },
    goTo: function (to) {
      this.$refs.carousel.goTo(to);
    },
  },
  created: function () {
    // if (this.list.length) this.current = 0;
  }
}
</script>

<style scoped lang="scss">
* {
  //outline: 1px solid red;
}

$media-break-point: 576px;

.gift-carousel {
  $room-for-arrow: 50px;

  @media all and (max-width: $media-break-point) {
    $room-for-arrow: 30px;
    margin: 0 -15px;
  }

  padding: 20px $room-for-arrow;
  background-color: #fefefe;
  border-radius: 10px;

  .gift {
    position: relative;
    padding: 0 10px;

    .img-wrapper {
      margin: 0 auto 15px;
      height: 250px;

      @media all and (max-width: $media-break-point) {
        height: 180px;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        border-radius: 8px;
      }
    }

    .gift-title {
      position: relative;
      margin: 0 (-$room-for-arrow);
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      @media all and (max-width: $media-break-point) {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

/** slick override **/
.ant-carousel .custom-slick-arrow {
  $size: 35px;

  @media all and (max-width: $media-break-point) {
    $size: 30px;
  }

  width: $size;
  height: $size;
  font-size: $size;
  border-radius: 50%;
  color: #666 !important;
  background-color: rgba(31, 45, 61, 0.11) !important;
  opacity: 0.4;
  transition: all .2s;

  &:hover {
    opacity: 0.7 !important;
    transition: all .2s;
  }

  $shift: 3px;

  &.left {
    left: -($size + $shift);
    z-index: 1;
  }

  &.right {
    right: -($size + $shift);
  }
}

.ant-carousel .custom-slick-arrow:before {
  display: none;
}

.ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}

</style>