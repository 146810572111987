<template>
  <div id="app">
    <!--        <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <Register></Register>
  </div>
</template>

<script>
import Register from './components/register/Register';

export default {
  name: 'App',
  components: {
    Register,
  },
  created: function () {
    document.title = 'Amazon Warranty';
  }
}
</script>

<style>
#app {
  font-family: "PingFang SC", DengXian, Avenir, Helvetica, Arial, sans-serif, SansSerif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
