import axios from "axios";

export let axiosGet = function (url, data) {
    return axios.get(url, {
        params: data
    });
};

export let axiosPost = function (url, data) {
    return axios.post(url, data);
};