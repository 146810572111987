import Vue from 'vue'
import App from './App.vue'

// import Antd from 'ant-design-vue';
// Vue.use(Antd);
import 'ant-design-vue/dist/antd.css';

// eslint-disable-next-line
import {
    Row,
    Col,
    Button,
    Modal,
    Card,
    Carousel,
    Icon,
    Input,
    message,
    Rate,
    Space,
    Divider,
    Dropdown,
    Menu,
} from 'ant-design-vue';

Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(Modal);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Input);
Vue.use(Rate);
Vue.use(message);
Vue.prototype.$message = message;
Vue.use(Space);
Vue.use(Divider);
Vue.use(Dropdown);
Vue.use(Menu);

import 'animate.css';

Vue.config.productionTip = false


let dev_mode;
dev_mode = true;
dev_mode = false; /** uncomment this for production build*/

let server_backend_prod;
// server_backend_prod = 'http://47.115.177.24:8099'; // 原亚马逊 ERP Windows Server，不用了
// server_backend_prod = 'http://8.136.110.108:8099'; // 用于开发阶段功能演示的临时服务器，不用了
// server_backend_prod = 'http://47.243.12.65:8099'; // 正式服务器（旧）（东莞）
server_backend_prod = 'http://47.254.25.134:8099'; // 正式服务器（中鸿升重新启用该项目）
// TODO ⬆ 加上 /amzWarranty 前缀，这样业务代码中就不用每个都加了

if (dev_mode) {
    Vue.prototype.$apiBaseUrl = ''; /* would use devServe.proxy settings */
    Vue.prototype.$imagePrefix = 'http://127.0.0.1:8099/amzWarranty/sys/common/static/';
} else {
    Vue.prototype.$apiBaseUrl = server_backend_prod;
    Vue.prototype.$imagePrefix = `${server_backend_prod}/amzWarranty/sys/common/static/`;
}

new Vue({
    render: h => h(App),
}).$mount('#app')
