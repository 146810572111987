// eslint-disable-next-line

/*eslint-disable*/

let settings = {
    starLeastToAmazon: 4,  // 需高于此评星才引导至亚马逊留评
    reviewLeastLength: 35, // 留评内容最少字数
};

let ui_config_default = {
    lang: undefined,
    general: {
        description: '通用UI设置',
        emailFormatHint: 'Invalid email format.',
    },
    welcome: {
        description: '注册首页视图',
        titles: [
            {d: 'Activate your warranty.'},
            {d: 'And get a FREE gift.'},
        ],
        contents: [
            {d: '* Once your warranty is successfully activated, you may choose one of our exclusive gifts, which is completely free of charge.'},
            {d: '* You need to finish the following form first.'},
            {d: ''},
        ],
        links: {
            facebook: '',
            instagram: '',
        },
        form_labels: {
            order_id: '*Order ID on Amazon:',
            email: '*Email of your Amazon account:',
            name: '*Your full name:',
        },
        button: 'Claim longer warranty',
    },
    tips4InvalidOrderId: {
        description: '订单号不存在时的提示',
        title: 'Sorry, we have no record for this order id.',
        contents: [
            {d: 'You may need to recheck the order id and the corresponding Amazon account.'},
            {d: ''},
        ],
        buttons: {
            retry: 'Try again',
            contact_us: 'Contact us',
        }
    },
    tips4RegisteredOrderId: {
        description: '订单号已注册时的提示',
        title: 'Dear Customer, this product has claimed longer warranty.',
        code_hint: 'Your Warranty code is:',
        contents: [
            {d: 'Thank you!'},
            {d: ''},
        ],
        buttons: {
            back: 'Back',
            contact_us: 'Contact us',
        }
    },
    chooseGift: {
        description: '选择礼品',
        title1: 'Congratulations, the warranty is successfully activated.',
        code_hint: 'Your Warranty code is:',
        title1_2: 'Thanks for purchasing our products.',
        title2: 'Here is a FREE gift for you.',
        contents: [
            {d: '* No shipping charges, no hidden fees, no credit card is required.'},
            {d: '* All you need to do is to tell us your experience about the product you purchased.'},
            {d: '* It would only takes you around 5 minutes.'},
            {d: ''},
        ],
        button: 'I want this one !',
    },
    starAndReview: {
        description: '评星与留评',
        title: 'Tell us your experience about the product.',
        labels: {
            rating: '*Overall rating:',
            comment: '*How do you like our product?',
        },
        button: 'Continue',
        contents: [],
    },
    guide2AmzReview: {
        description: '引导至亚马逊留评',
        title: 'Share your experience.',
        contents: [
            {d: '* Your review will then be seen by other customers, helping them to make a better purchase decision.'},
            {d: '* Thanks sincerely for your kindness, COME BACK to this page from Amazon when you\'re done.'},
            {d: '* Here is your review:'},
            {d: ''},
        ],
        buttons: {
            copy: 'Copy review content',
            to_amazon: 'Post on Amazon',
        },
        amzLink: 'https://www.amazon.com/gp/css/order-history',
    },
    enquireAmzReview: {
        description: '询问是否已在亚马逊留评',
        title: 'Have you copied and posted your review on Amazon?',
        contents: [],
        buttons: {
            no: 'Not Yet',
            yes: 'YES',
        }
    },
    confirmGift: {
        description: '确认礼品',
        title: 'Thanks for the review, here is the gift for you.',
        contents: [
            {d: 'Feel free to choose another one before submitting your shipping address.'},
            {d: ''},
        ],
        form_labels: {
            name: '*Name:',
            address: '*Shipping address:',
            city: '*City:',
            region: '*State/Province/Region:',
            country: '*Country:',
            postcode: '*Postcode:',
            phone: 'Phone:',
            email_gift: 'Email:',
            email_paypal: '*Paypal account:',
            email_coupon: '*Email to receive the coupon code:',
        },
        button: 'Get this gift!',
    },
    tips4Ending: {
        description: '流程结束提示',
        title: 'Congratulations! It\'s all set.',
        contents: [
            {d: 'Your gift is being scheduled. '},
            {d: ''},
        ],
        contact_us: {
            hint: 'If you need help.',
            button: 'Contact us',
        }
    },
    contactUs: {
        description: '联系我们（留言）',
        title: 'Contact us',
        contents: [],
        form_labels: {
            name: '*Name:',
            email: '*Email:',
            subject: '*Subject:',
            content: '*Content:',
        },
        button: 'Send',
    },
    tips4contactUsSent: {
        description: '留言成功提示',
        title: 'Thank you.',
        contents: [
            {d: 'Your message is received.'},
            {d: 'We will get back to you soon.'},
            {d: ''},
        ],
    },
    help: {
        description: '帮助信息',
        title: 'About',
        contents: [
            {d: 'We provide longer warranty for the product you purchased from our official seller on Amazon.'},
            {d: 'We also prepare an exclusive FREE gift for the customer who buy our products for the first time.'},
            {d: 'All you need to do is to follow the instructions by steps.'},
            {d: ''},
        ],
        button: 'OK',
    },
};

let ui_config_jp = {
    lang: 'jp',
    general: {
        description: '通用UI设置',
        emailFormatHint: 'メール形式が無効です。',
    },
    welcome: {
        description: '注册首页视图',
        titles: [
            {d: '保証を有効にします。'},
            {d: 'そして、無料のギフトを入手してください。'},
        ],
        contents: [
            {d: '※ 保証が正常に有効化されると、完全に無料の限定ギフトの 1 つを選択できます。'},
            {d: '※ まずは以下のフォームにご記入ください。'},
            {d: ''},
        ],
        links: {
            facebook: '',
            instagram: '',
        },
        form_labels: {
            order_id: '* Amazon での注文 ID：',
            email: '* Amazon アカウントの E メール：',
            name: '* あなたのフルネーム：',
        },
        button: 'より長い保証を請求する',
    },
    tips4InvalidOrderId: {
        description: '订单号不存在时的提示',
        title: '申し訳ありませんが、この注文 ID の記録はありません。',
        contents: [
            {d: '注文 ID と対応する Amazon アカウントを再確認する必要がある場合があります。'},
            {d: ''},
        ],
        buttons: {
            retry: '再試行',
            contact_us: 'お問い合わせ',
        }
    },
    tips4RegisteredOrderId: {
        description: '订单号已注册时的提示',
        title: 'お客様各位、この製品はより長い保証を主張しています。',
        code_hint: 'あなたの保証コードは：',
        contents: [
            {d: 'ありがとう。'},
            {d: ''},
        ],
        buttons: {
            back: 'バック',
            contact_us: 'お問い合わせ',
        }
    },
    chooseGift: {
        description: '选择礼品',
        title1: 'おめでとうございます、保証は正常にアクティブ化されました。',
        code_hint: 'あなたの保証コードは：',
        title1_2: '製品をご購入いただきありがとうございます。',
        title2: 'これがあなたへの無料ギフトです。',
        contents: [
            {d: '※ 配送料、隠れた料金、クレジットカードは必要ありません。'},
            {d: '※ あなたがする必要があるのはあなたが購入した製品についてのあなたの経験を私たちに話すことだけです。'},
            {d: '※ 所要時間は約 5 分です。'},
            {d: ''},
        ],
        button: '私はこれが欲しい ！',
    },
    starAndReview: {
        description: '评星与留评',
        title: '製品についてのあなたの経験を教えてください。',
        labels: {
            rating: '* 総合評価：',
            comment: '* 私たちの製品はどうですか？',
        },
        button: '継続する',
        contents: [],
    },
    guide2AmzReview: {
        description: '引导至亚马逊留评',
        title: 'あなたの経験を共有してください。',
        contents: [
            {d: '※ あなたのレビューは他の顧客に見られ、彼らがより良い購入決定を下すのに役立ちます。'},
            {d: '※ よろしくお願いします。終わったら、Amazon からこのページに戻ってください。'},
            {d: '※ ここにあなたのレビューがあります：'},
            {d: ''},
        ],
        buttons: {
            copy: '複写',
            to_amazon: 'アマゾンに投稿',
        },
        amzLink: 'https://www.amazon.co.jp/gp/css/order-history',
    },
    enquireAmzReview: {
        description: '询问是否已在亚马逊留评',
        title: 'レビューをコピーして Amazon に投稿しましたか？',
        contents: [],
        buttons: {
            no: '未だに',
            yes: 'はい',
        }
    },
    confirmGift: {
        description: '确认礼品',
        title: 'レビューをありがとう、ここにあなたへの贈り物があります。',
        contents: [
            {d: '配送先住所を送信する前に、別の住所を自由に選択してください。'},
            {d: ''},
        ],
        form_labels: {
            name: '* 名前：',
            address: '* お届け先の住所：',
            city: '* 市:',
            region: '* 州/県/区:',
            country: '* 国:',
            postcode: '* 郵便番号：',
            phone: '電話：',
            email_gift: 'Email:',
            email_paypal: '* PayPalアカウント：',
            email_coupon: '* クーポンコードを受け取るための電子メール：',
        },
        button: 'このギフトをゲット！',
    },
    tips4Ending: {
        description: '流程结束提示',
        title: 'おめでとう！それはすべて設定されています。',
        contents: [
            {d: 'あなたの贈り物は予定されています。'},
            {d: ''},
        ],
        contact_us: {
            hint: 'あなたが助けを必要とする場合。',
            button: 'お問い合わせ',
        }
    },
    contactUs: {
        description: '联系我们（留言）',
        title: 'お問い合わせ',
        contents: [],
        form_labels: {
            name: '* 名前：',
            email: '* Email:',
            subject: '* 件名：',
            content: '* コンテンツ：',
        },
        button: '送信',
    },
    tips4contactUsSent: {
        description: '留言成功提示',
        title: 'ありがとうございました。',
        contents: [
            {d: 'メッセージを受信します。'},
            {d: 'すぐにご連絡いたします。'},
            {d: ''},
        ],
    },
    help: {
        description: '帮助信息',
        title: '説明',
        contents: [
            {d: 'Amazon の公式販売者から購入した商品には、より長い保証が付いています。'},
            {d: 'また、初めてお買い上げのお客様には、無料ギフトをご用意しております。'},
            {d: 'あなたがする必要があるのは、ステップごとの指示に従うことです。'},
            {d: ''},
        ],
        button: '確認',
    },
};

function genSettingsWithLangInfo(lang) {
    let ui_settings = JSON.parse(JSON.stringify(ui_config_default));
    ui_settings.lang = lang;

    let domain = 'www.amazon.com';
    switch (lang) {
        case 'us': domain = 'www.amazon.com'; break;
        case 'ca': domain = 'www.amazon.ca'; break;
        case 'uk': domain = 'www.amazon.co.uk'; break;
        case 'de': domain = 'www.amazon.de'; break;
        case 'fr': domain = 'www.amazon.fr'; break;
        case 'it': domain = 'www.amazon.it'; break;
        case 'es': domain = 'www.amazon.es'; break;
        case 'jp': domain = 'www.amazon.co.jp'; break;
    }
    ui_settings.guide2AmzReview.amzLink = `https://${domain}/gp/css/order-history`;

    return ui_settings;
}

settings.i18n = {
    us: genSettingsWithLangInfo('us'),
    ca: genSettingsWithLangInfo('ca'),
    uk: genSettingsWithLangInfo('uk'),
    de: genSettingsWithLangInfo('de'),
    fr: genSettingsWithLangInfo('fr'),
    it: genSettingsWithLangInfo('it'),
    es: genSettingsWithLangInfo('es'),
    jp: ui_config_jp,
};

export default settings;
