<template>
  <a-row type="flex" justify="center" align="middle" class="register-ctnr">
    <a-card :bordered="false" class="card-wrapper" v-if="settings.i18n">

      <!-- examine order id -->
      <div class="process-card"
           :class="generateDynamicClass4StepCard(steps.welcome)">
        <div class="bg-holder bg multiple bg-size-60 bg-position-x-n55 opacity-85"></div>
        <a-card :loading="ui.loading.welcome">
          <a-row class="process" type="flex" align="middle"
                 :gutter="ui.main_2_col_gutter_h">
            <a-col :xs="24" :sm="13">
              <div class="bg-holder bg people bg-size-60 bg-position-x-95 bg-position-y-100 opacity-30"></div>
              <div class="h-px-50"></div>
              <h2>
                <span v-for="(t, i) in lp.welcome.titles" :key="i">
                  {{ t.d }} <br v-if="i !== lp.welcome.titles.length - 1">
                </span>
                <a-icon type="gift"></a-icon>
              </h2>
              <p v-for="(p,i) in lp.welcome.contents" :key="i">{{ p.d }}</p>
              <p style="font-size: 1.15em">
                <a @click.prevent="toMediaSite(lp.welcome.links.facebook)">
                  <a-icon type="facebook"/>
                  Facebook
                </a>
                <a-divider type="vertical"/>
                <a @click.prevent="toMediaSite(lp.welcome.links.instagram)">
                  <a-icon type="instagram"/>
                  Instagram
                </a>
              </p>
              <div class="h-px-80"></div>
            </a-col>
            <a-col :xs="24" :sm="0" class="vertical-space-holder"></a-col>
            <a-col :xs="24" :sm="11">
              <a-row :gutter="[0, ui.form_control_gutter_v]">
                <a-col :span="24">
                  <label for="order-id">{{ lp.welcome.form_labels.order_id }}</label>
                  <a-input id="order-id" v-model="form.orderId"></a-input>
                </a-col>
                <a-col :span="24">
                  <label for="email">{{ lp.welcome.form_labels.email }}</label>
                  <a-input id="email" v-model="form.email"></a-input>
                  <p class="hint-err" v-if="form.email && !isValidEmail(form.email)">
                    {{ lp.general.emailFormatHint }}
                  </p>
                </a-col>
                <a-col :span="24">
                  <label for="name">{{ lp.welcome.form_labels.name }}</label>
                  <a-input id="name" v-model="form.name"></a-input>
                </a-col>
                <a-col :span="24">
                  <a-button block type="primary" size="large"
                            :disabled="!(form.orderId && form.name && isValidEmail(form.email))"
                            @click="checkOrderId">
                    {{ lp.welcome.button }}
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- tips for invalid order id -->
      <div class="process-card w-50"
           :class="generateDynamicClass4StepCard(steps.tips4InvalidOrderId)">
        <div class="bg-holder bg paper-plane bg-size-60 bg-position-x-n55 opacity-90"></div>
        <div class="bg-holder bg woman-shrug bg-size-55 bg-position-x-130 bg-position-y-110 opacity-70"></div>
        <a-card>
          <a-row class="process" :gutter="[ui.main_2_col_gutter_h, 10]">
            <a-col :span="24">
              <h3>
                {{ lp.tips4InvalidOrderId.title }}
                <a-icon type="frown"></a-icon>
              </h3>
              <p v-for="(p,i) in lp.tips4InvalidOrderId.contents" :key="i">{{ p.d }}</p>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-button block type="primary" size="large" @click="jumpToStep(steps.welcome)">
                {{ lp.tips4InvalidOrderId.buttons.retry }}
              </a-button>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-button block type="default" size="large" @click="jumpToStep(steps.contactUs)">
                {{ lp.tips4InvalidOrderId.buttons.contact_us }}
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- tips for order id that has been registered. -->
      <div class="process-card w-50"
           :class="generateDynamicClass4StepCard(steps.tips4RegisteredOrderId)">
        <div class="bg-holder bg woman-like bg-size-50 bg-position-x-120 bg-position-y-150 opacity-70"></div>
        <a-card>
          <a-row class="process" :gutter="[ui.main_2_col_gutter_h, 10]">
            <a-col :span="24">
              <h3 style="margin-bottom: 0">
                {{ lp.tips4RegisteredOrderId.title }}
                <a-icon type="heart" theme="filled"></a-icon>
              </h3>
            </a-col>
            <a-col :span="24">
              <p v-if="client">
                {{ lp.tips4RegisteredOrderId.code_hint }}
                <strong>{{ client.warrantyCode }}</strong>
              </p>
              <p v-for="(p,i) in lp.tips4RegisteredOrderId.contents" :key="i">{{ p.d }}</p>
              <!--            <a-button block type="primary" size="large" @click="jumpToStep(steps.welcome)">-->
              <!--              Back-->
              <!--            </a-button>-->
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-button block type="default" size="large" @click="jumpToStep(steps.welcome)">
                {{ lp.tips4RegisteredOrderId.buttons.back }}
              </a-button>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-button block type="primary" size="large" @click="jumpToStep(steps.contactUs)">
                {{ lp.tips4RegisteredOrderId.buttons.contact_us }}
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- when order id is valid, present gifts. -->
      <div class="process-card"
           :class="generateDynamicClass4StepCard(steps.chooseGift)">
        <div class="bg-holder bg semicircle-blue-line-1
                    bg-size-40 bg-position-x-n10 bg-position-y-120 opacity-75"></div>
        <div class="bg-holder bg quarter-circle-yellow-line
                    bg-size-30 bg-position-x-110 bg-position-y-0 opacity-75"></div>
        <a-card :loading="ui.loading.selectGift">
          <a-row class="process" :gutter="ui.main_2_col_gutter_h"
                 type="flex" align="middle">
            <a-col :xs="24" :sm="12">
              <h2>
                {{ lp.chooseGift.title1 }}
              </h2>
              <p v-if="client"> {{ lp.chooseGift.code_hint }} {{ client.warrantyCode }} </p>
              <h4>{{ lp.chooseGift.title1_2 }}</h4>
              <a-row type="flex" justify="center" style="text-align: center">
                <!--                  <a-col :xs="24" :sm="8" style="margin:10px 0;">-->
                <!--                    <img src="" alt="">-->
                <!--                    <a-icon type="file-image" style="font-size: 90px"></a-icon>-->
                <!--                  </a-col>-->
                <a-col :span="24" v-if="order">
                  <a-card size="small"><small>{{ order.productName }}</small></a-card>
                </a-col>
              </a-row>
              <br>
              <br>
              <h2>
                {{ lp.chooseGift.title2 }}
                <a-icon type="gift"></a-icon>
              </h2>
              <p v-for="(p,i) in lp.chooseGift.contents" :key="i">{{ p.d }}</p>
            </a-col>
            <a-col :xs="24" :sm="12">
              <gift-selector :list="gifts" @change="setCurGiftSelectedAndSyncSelector2"></gift-selector>
              <a-button block type="primary" size="large"
                        @click="saveSelectedGift()"
                        :disabled="!gifts.length">
                {{ lp.chooseGift.button }}
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- leave review and stars in the system. -->
      <div class="process-card w-75"
           :class="generateDynamicClass4StepCard(steps.starAndReview)">
        <div class="bg-holder bg semicircle-blue-yellow bg-size-130 bg-position-x-0 bg-position-y-0 opacity-75"></div>
        <a-card :loading="ui.loading.saveReview">
          <a-row class="process" :gutter="ui.main_2_col_gutter_h">
            <a-col :span="24">
              <h2>
                {{ lp.starAndReview.title }}
                <a-icon type="highlight"></a-icon>
              </h2>
            </a-col>
            <a-col :span="24">
              <a-row :gutter="[0, ui.form_control_gutter_v]">
                <a-col :span="24">
                  <label for="rating">{{ lp.starAndReview.labels.rating }}</label>
                  <div>
                    <a-rate id="rating" :allowClear="false" v-model="form.stars" style="font-size: 30px"/>
                  </div>
                </a-col>
                <a-col :span="24">
                  <label for="reivew">{{ lp.starAndReview.labels.comment }}</label>
                  <a-textarea id="reivew" v-model="form.review"
                              allowClear :placeholder="'minimum characters requirement: ' + settings.reviewLeastLength"
                              :autoSize="{ minRows: 7, maxRows: 16 }"
                  />
                </a-col>
                <a-col :span="24">
                  <a-button block type="primary" size="large" @click="saveReview"
                            :disabled="!(form.stars && form.review.length >= settings.reviewLeastLength)">
                    {{ lp.starAndReview.button }}
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- guide user to leave the review on Amazon. -->
      <div class="process-card w-75"
           :class="generateDynamicClass4StepCard(steps.guide2AmzReview)">
        <div class="bg-holder bg woman-like bg-size-40 bg-position-x-98 bg-position-y-2 opacity-60"></div>
        <a-card>
          <a-row class="process" :gutter="[ui.main_2_col_gutter_h, ui.form_control_gutter_v]">
            <a-col :span="24">
              <h2>{{ lp.guide2AmzReview.title }}</h2>
              <p v-for="(p,i) in lp.guide2AmzReview.contents" :key="i">{{ p.d }}</p>
              <a-card size="small" style="text-align: justify; white-space: pre-line; word-break: break-all;">
                {{ form.review }}
              </a-card>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-button block size="large"
                        class="copy-review-btn" :data-clipboard-text="form.review">
                {{ lp.guide2AmzReview.buttons.copy }}
                <a-icon type="copy"></a-icon>
              </a-button>
            </a-col>
            <a-col :xs="24" :sm="12">
              <a-button block size="large" type="primary"
                        class="copy-review-btn" :data-clipboard-text="form.review"
                        @click="toAmazonSite(lp.guide2AmzReview.amzLink)">
                {{ lp.guide2AmzReview.buttons.to_amazon }}
                <a-icon type="amazon"></a-icon>
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- enquire user if had posted review on Amazon. -->
      <div class="process-card w-60"
           :class="generateDynamicClass4StepCard(steps.enquireAmzReview)">
        <div class="bg-holder bg paper-plane bg-size-45 bg-position-x-n20 bg-position-y-0 opacity-80"></div>
        <a-card>
          <a-row class="process" :gutter="ui.main_2_col_gutter_h">
            <a-col :span="24">
              <h3>{{ lp.enquireAmzReview.title }}</h3>
            </a-col>
            <a-col :span='24' class="vertical-space-holder"></a-col>
            <a-col :span="12">
              <a-button block @click="saveAmzReviewStatus(false)">
                {{ lp.enquireAmzReview.buttons.no }}
              </a-button>
            </a-col>
            <a-col :span="12">
              <a-button block type="primary" @click="saveAmzReviewStatus(true)">
                {{ lp.enquireAmzReview.buttons.yes }}
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- confirm selected gift and shipping address. -->
      <div class="process-card"
           :class="generateDynamicClass4StepCard(steps.confirmGift)">
        <div class="bg-holder bg semicircle-blue-line-2
                    bg-size-40 bg-position-x-n10 bg-position-y-100 opacity-75"></div>
        <a-card :loading="ui.loading.saveShippingAddress">
          <a-row class="process" :gutter="ui.main_2_col_gutter_h"
                 type="flex" align="middle">
            <a-col :xs="24" :sm="12">
              <h3>{{ lp.confirmGift.title }}</h3>
              <p v-for="(p,i) in lp.confirmGift.contents" :key="i">{{ p.d }}</p>
              <gift-selector :list="gifts" @change="setCurGiftSelected" ref="giftSelector2"></gift-selector>
            </a-col>
            <a-col :xs="24" :sm="0" class="vertical-space-holder"></a-col>
            <a-col :xs="24" :sm="12">
              <a-row :gutter="[0, ui.form_control_gutter_v]"
                     v-if="gifts.length">
                <template v-if="gifts[curGiftSelected].type === giftType.entity">
                  <a-col :span="24">
                    <label for="name2">{{ lp.confirmGift.form_labels.name }}</label>
                    <a-input id="name2" v-model="form.name"></a-input>
                  </a-col>
                  <a-col :span="24">
                    <label for="email-gift">{{ lp.confirmGift.form_labels.email_gift }}</label>
                    <a-input id="email-gift" v-model="form.email"></a-input>
                  </a-col>
                  <a-col :span="24">
                    <label for="address">{{ lp.confirmGift.form_labels.address }}</label>
                    <a-input id="address" v-model="form.address"></a-input>
                    <!--<a-textarea id="address" v-model="form.address"-->
                    <!--            allowClear :autoSize="{ minRows: 6, maxRows: 10 }"></a-textarea>-->
                  </a-col>
                  <a-col :span="24">
                    <label for="city">{{ lp.confirmGift.form_labels.city }}</label>
                    <a-input id="city" v-model="form.city"></a-input>
                  </a-col>
                  <a-col :span="24">
                    <label for="region">{{ lp.confirmGift.form_labels.region }}</label>
                    <a-input id="region" v-model="form.region"></a-input>
                  </a-col>
                  <a-col :span="24">
                    <label for="country">{{ lp.confirmGift.form_labels.country }}</label>
                    <a-input id="country" v-model="form.country"></a-input>
                  </a-col>
                  <a-col :span="24">
                    <label for="postcode">{{ lp.confirmGift.form_labels.postcode }}</label>
                    <a-input id="postcode" v-model="form.postcode"></a-input>
                  </a-col>
                  <a-col :span="24">
                    <label for="phone">{{ lp.confirmGift.form_labels.phone }}</label>
                    <a-input id="phone" v-model="form.phone"></a-input>
                  </a-col>
                </template>

                <template v-if="gifts[curGiftSelected].type === giftType.cash">
                  <a-col :span="24">
                    <label for="paypal">{{ lp.confirmGift.form_labels.email_paypal }}</label>
                    <a-input id="paypal" v-model="form.paypal"></a-input>
                    <p class="hint-err" v-if="form.paypal && !isValidEmail(form.paypal)">
                      {{ lp.general.emailFormatHint }}
                    </p>
                  </a-col>
                </template>

                <template v-if="gifts[curGiftSelected].type === giftType.coupon">
                  <a-col :span="24">
                    <label for="email2">{{ lp.confirmGift.form_labels.email_coupon }}</label>
                    <a-input id="email2" v-model="form.email"></a-input>
                    <p class="hint-err" v-if="form.email && !isValidEmail(form.email)">
                      {{ lp.general.emailFormatHint }}
                    </p>
                  </a-col>
                </template>

                <a-col :span="24">
                  <a-button block type="primary" size="large"
                            :disabled="!isShippingAddressValid"
                            @click="saveShippingAddress">
                    {{ lp.confirmGift.button }}
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- end -->
      <div class="process-card w-75"
           :class="generateDynamicClass4StepCard(steps.tips4Ending)">
        <div class="bg-holder bg double-triangle bg-size-33 bg-position-x-110 bg-position-y-10 opacity-75"></div>
        <a-card>
          <a-row class="process">
            <a-col :span="24">
              <h2>
                {{ lp.tips4Ending.title }}
                <a-icon type="smile"></a-icon>
              </h2>
              <p v-for="(p,i) in lp.tips4Ending.contents" :key="i">{{ p.d }}</p>
              <p>
                {{ lp.tips4Ending.contact_us.hint }}
                <span v-html="'&nbsp;&nbsp;'"></span>
                <a-button type="default" size="default"
                          @click="jumpToStep(steps.contactUs)">
                  {{ lp.tips4Ending.contact_us.button }}
                </a-button><!--todo: 加个循环的小动画-->
              </p>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- contact us -->
      <div class="process-card w-75"
           :class="generateDynamicClass4StepCard(steps.contactUs)">
        <div class="bg-holder bg line-1 bg-size-33 bg-position-x-105 bg-position-y-1 opacity-75"></div>
        <a-card :loading="ui.loading.saveContactUsContent">
          <a-row class="process" :gutter="[0, ui.form_control_gutter_v]">
            <h1 style="text-align: center;">{{ lp.contactUs.title }}</h1>
            <a-col :span="24">
              <label for="name3">{{ lp.contactUs.form_labels.name }}</label>
              <a-input id="name3" v-model="form.name"></a-input>
            </a-col>
            <a-col :span="24">
              <label for="email3">{{ lp.contactUs.form_labels.email }}</label>
              <a-input id="email3" v-model="form.email"></a-input>
              <p class="hint-err" v-if="form.email && !isValidEmail(form.email)">
                {{ lp.general.emailFormatHint }}
              </p>
            </a-col>
            <a-col :span="24">
              <label for="title">{{ lp.contactUs.form_labels.subject }}</label>
              <a-input id="title" v-model="form.contact_title"></a-input>
            </a-col>
            <a-col :span="24">
              <label for="content">{{ lp.contactUs.form_labels.content }}</label>
              <a-textarea id="content" v-model="form.contact_content"
                          allowClear :autoSize="{ minRows: 6, maxRows: 10 }"></a-textarea>
            </a-col>
            <a-col :span="24">
              <a-button block type="primary" size="large"
                        :disabled="!(bizId && form.name && isValidEmail(form.email) && form.contact_title && form.contact_content)"
                        @click="saveContactUsContent">
                {{ lp.contactUs.button }}
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <!-- tips 4 contact-us info sent -->
      <div class="process-card w-50"
           :class="generateDynamicClass4StepCard(steps.tips4contactUsSent)">
        <div class="bg-holder bg semicircle-yellow-line
                    bg-size-50 bg-position-x-n10 bg-position-y-140 opacity-75"></div>
        <a-card>
          <a-row class="process">
            <a-col :span="24">
              <h2>{{ lp.tips4contactUsSent.title }}</h2>
              <p v-for="(p,i) in lp.tips4contactUsSent.contents" :key="i">{{ p.d }}</p>
            </a-col>
          </a-row>
        </a-card>
      </div>

    </a-card>

    <!-- help info modal -->
    <a-icon type="question-circle" id="help-btn" @click="ui.helpModalVisible = true"/>
    <a-modal v-model="ui.helpModalVisible" :title="lp.help.title" centered>
      <p v-for="(p,i) in lp.help.contents" :key="i">{{ p.d }}</p>
      <template slot="footer">
        <a-button type="primary" @click="ui.helpModalVisible = false">{{ lp.help.button }}</a-button>
      </template>
    </a-modal>

    <!-- change language -->
    <a-dropdown id="i18n-btn">
      <a-menu slot="overlay" style="font-family: Menlo, Consolas, sans-serif">
        <a-menu-item @click="setLanguage('us')">🇺🇸 US</a-menu-item>
        <a-menu-item @click="setLanguage('ca')">🇨🇦 CA</a-menu-item>
        <a-menu-item @click="setLanguage('uk')">🇬🇧 UK</a-menu-item>
        <a-menu-item @click="setLanguage('de')">🇩🇪 DE</a-menu-item>
        <a-menu-item @click="setLanguage('fr')">🇫🇷 FR</a-menu-item>
        <a-menu-item @click="setLanguage('it')">🇮🇹 IT</a-menu-item>
        <a-menu-item @click="setLanguage('es')">🇪🇸 ES</a-menu-item>
        <a-menu-item @click="setLanguage('jp')">🇯🇵 JP</a-menu-item>
      </a-menu>
      <a-button>
        <a-icon type="global"/>
        <a-icon type="down" style="margin:0 -3px 0 3px;"/>
      </a-button>
    </a-dropdown>

    <!-- bizId valid-indicator-->
    <div class="bizId-indicator" :class="{valid: bizId}"></div>

    <!-- debug only -->
    <a-card id="debug" v-if="debug.ui">
      <a-space direction="vertical">
        <a-button v-for="(s, key) in steps" v-bind:key="key" @click="jumpToStep(s)">
          {{ s }} - {{ key }}
        </a-button>
      </a-space>
    </a-card>
  </a-row>
</template>

<script>
import GiftSelector from "./GiftSelector/GiftSelector";
import settings from './default_settings';
// eslint-disable-next-line
import {axiosGet, axiosPost} from '@/assets/utils';
import Clipboard from "clipboard/dist/clipboard";
// eslint-disable-next-line
import defaultsdeep from 'lodash.defaultsdeep';

export default {
  name: "Register",
  components: {
    GiftSelector,
  },
  data: function () {
    return {
      debug: {
        // ui: true,
        ui: false,
        // logic: true,
        logic: false,
      },

      ui: {
        main_2_col_gutter_h: 24,
        form_control_gutter_v: 24,
        helpModalVisible: false,
        loading: {
          welcome: false,
          selectGift: false,
          saveReview: false,
          saveShippingAddress: false,
          saveContactUsContent: false,
        }
      },

      steps: {
        welcome: 1,
        tips4InvalidOrderId: 101,
        tips4RegisteredOrderId: 102,
        chooseGift: 2,
        starAndReview: 3,
        guide2AmzReview: 4,
        enquireAmzReview: 5,
        confirmGift: 6,
        tips4Ending: 7,

        contactUs: 8,
        tips4contactUsSent: 801,
      },
      curStep: 1, /* 调试时，要直接显示某个步骤的界面，可修改这里的数字。生产环境中，应该是 1 */
      notAmzCommentCount: 0,

      giftType: {
        entity: 1, //实物礼品
        cash: 2,   //现金
        coupon: 3, //优惠码
      },
      gifts: [],
      curGiftSelected: 0,
      order: null,
      client: null,

      form: {
        orderId: '',
        // orderId: '249-5124243-1615031',
        // orderId: '249-1587564-4472619',
        // orderId: '249-6511219-9480637',
        email: '',
        name: '',

        stars: null,
        review: '',
        // review: 'DEBUG ONLY: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet aspernatur atque consequuntur, itaque magni neque quas rerum sed vero.',

        address: '',
        city: '',
        region: '',
        country: '',
        postcode: '',
        paypal: '',
        phone: '',

        contact_title: '',
        contact_content: '',
      },

      settings: {},
      bizId: null,
      lp: null, // language pack: hold texts for current selected language
    }
  },
  computed: {
    isShippingAddressValid: function () {
      let valid = false;
      switch (this.gifts[this.curGiftSelected].type) {
        case this.giftType.entity:
          valid = this.form.name
              && this.form.address
              && this.form.city
              && this.form.region
              && this.form.country
              && this.form.postcode;
          break;
        case this.giftType.cash:
          valid = this.isValidEmail(this.form.paypal);
          break;
        case this.giftType.coupon:
          valid = this.isValidEmail(this.form.email);
          break;
      }
      return valid;
    },
  },
  methods: {
    /* UI relative */
    _isCurStep: function (stepCode) {
      return this.curStep === stepCode;
    },
    jumpToStep: function (stepCode) {
      // console.log(stepCode);
      this.curStep = stepCode;
    },
    generateDynamicClass4StepCard: function (stepCode) {
      return {
        active: this._isCurStep(stepCode),
        animate__animated: this._isCurStep(stepCode),
        // animate__bounceIn: this._isCurStep(stepCode),
        // animate__slideInUp: this._isCurStep(stepCode),
        animate__fadeIn: this._isCurStep(stepCode),
      }
    },
    setCurGiftSelected: function (current) {
      // console.log('current selected gift：' + current);
      this.curGiftSelected = current;
    },
    setCurGiftSelectedAndSyncSelector2: function (current) {
      // console.log('gift selector 2 is changed correspondingly to: ' + current);
      this.setCurGiftSelected(current);
      this.$refs.giftSelector2.goTo(current);
    },
    isValidEmail: function (email) {
      return email.toString().match(/.+@.+\..+/g);
    },

    /* Initializations */
    __initGiftList_debug: function () {
      this.gifts = [
        {
          title: 'SOL REPUBLIC Relay',
          src: require('./GiftSelector/sample-SOL_REPUBLIC_Relay.jpg'),
          type: this.giftType.entity,
        },
        // {
        //   title: 'Belkin Boost Charger',
        //   src: require('./GiftSelector/sample-Belkin_Boost_Charger.jpg'),
        //   type: this.giftType.entity,
        // },
        // {
        //   title: 'Nintendo Switch Car Charger',
        //   src: require('./GiftSelector/sample-Nintendo_Switch_Car_Charger.jpg'),
        //   type: this.giftType.entity,
        // },
        {
          title: 'Cash via PayPal',
          src: require('./GiftSelector/paypal_fc_hl.png'),
          type: this.giftType.cash,
        },
        {
          title: 'Amazon Coupon',
          src: require('./GiftSelector/coupon.png'),
          type: this.giftType.coupon,
        },
      ];
    },
    _initClipboardBtn: function () {
      const clipboard = new Clipboard('.copy-review-btn');
      clipboard.on('success', function () {
        this.$message.info('Copied.');
      }.bind(this));
    },
    _initBizId: function () {
      return new Promise((resolve) => {
        // 临时方案（次优先级）：从查询字符串获得 bizId，没有的话会返回 null。
        this.bizId = new URLSearchParams(location.search).get('bizId');
        /* 查询参数形如 ?bizId=1343959596087230465，开发时可加上这个对接后台调试 */

        // 正式方案（高优先级）：通过当前域名，询问后台当前域名属于哪个商家，获得对应的 bizId。
        axiosGet(
            this.$apiBaseUrl + '/amzWarranty/merchant/amzMerchant/getMerchantIdViaDomain',
            {
              /* 后台用来记录访客来源 */
              clientDomain: location.hostname,
              /* 后台用来记录访客来源｜这个是为了记录通过默认的固定买家端入口访问的情况。如果商家配置了自定义域名，后端会根据其来查询对应的 bizId， */
              bizId: this.bizId,
            }
        ).then(res => {
          // console.log(res);
          if (res.data.success
              && res.data.message) { // 无匹配时返回 null
            this.bizId = res.data.message;
          }
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          let msg = 'bizId initialized.';
          console.log(msg);
          resolve(msg);
        });
      });

      // 如果查不到则使用查询字符串中提供的 bizId，如果都没有则只能为空了，这样将无法获取注册失败的客户信息。
    },
    _initDefaultSettings: function () {
      // console.log(settings);
      this.settings.starLeastToAmazon = settings.starLeastToAmazon;
      this.settings.reviewLeastLength = settings.reviewLeastLength;
      this.settings.i18n = settings.i18n;
      console.log('default settings initialized.');
      this.setLanguage('us');
    },
    initCustomSettings: function () {
      if (this.bizId) {
        axiosGet(
            this.$apiBaseUrl + '/amzWarranty/merchant/amzMerchant/getClientUi',
            {bizId: this.bizId,}
        ).then(res => {
          // console.log(res);
          try {
            // 商家的客户端配置初始状态为空，此时使用默认设置作为当前配置
            // this.settings = JSON.parse(res.data.result.clientUi) || settings;
            this.settings = defaultsdeep(JSON.parse(res.data.result.clientUi || null) || {}, settings);
            this.settings.starLeastToAmazon = settings.starLeastToAmazon; // 临时：使用客户端的设置。
            this.settings.reviewLeastLength = settings.reviewLeastLength; // 临时：使用客户端的设置。

            console.log('custom settings initialized.');
            this.setLanguage('us');
          } catch (e) {
            console.error(e);
            console.error('initCustomSettings(): parse clientSettings failed.');
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        console.error('initCustomSettings(): no bizId available.');
      }
    },
    setLanguage: function (lang) {
      this.lp = this.settings.i18n[lang];
      console.log('language now sets to: ' + lang);
    },

    /* hyper-link related */
    toMediaSite: (url) => {
      if (url === '' || /javascript: *void/.test(url)) {
        return false;
      }
      window.open(url, '_blank');
    },
    toAmazonSite: function (url) {
      // make a timeout schedule to ensure clipboard.js is performed.
      setTimeout(function () {
        window.open(url, '_blank');
        this.jumpToStep(this.steps.enquireAmzReview);
      }.bind(this), 100);
    },

    /* business logics */
    checkOrderId: function () {
      this.ui.loading.welcome = true;
      axiosPost(this.$apiBaseUrl + '/amzWarranty/client/amzClient/register', {
        bizId: this.bizId,
        orderNumber: this.form.orderId.trim(),
        email: this.form.email.trim(),
        username: this.form.name.trim(),
        visitDomain: location.hostname, /* 注册用户需要保存其访问时的域名 */
      }).then(function (response) {
        console.log(response);

        /* if invalid */
        if (!response.data.success) {
          this.jumpToStep(this.steps.tips4InvalidOrderId);
          return 0;
        }

        // --------------- init start ---------------
        let result = response.data.result;
        // console.dir(result);

        // init client info
        this.client = result.amzClient;

        // init order info
        this.order = result;

        // init system settings (obsolete: now init bizId at the very beginning)
        // this.bizId = result.bizId;
        // this.initCustomSettings();

        // init gifts
        this.gifts = result.giftList.map(function (gift) {
          gift.title = gift.giftName;
          gift.desc = gift.description;
          gift.src = this.$imagePrefix + gift.photo;

          let type = gift.giftType;
          if (type.indexOf('现金') >= 0) {
            gift.type = this.giftType.cash;
          } else if (type.indexOf('优惠') >= 0) {
            gift.type = this.giftType.coupon;
          } else {
            gift.type = this.giftType.entity;
          }

          return gift;
        }.bind(this));
        // --------------- init end ---------------

        // -----  depending on what this client has done, jump to corresponding process  -------------
        let c = this.client;
        /* if have claimed gift at the final stage. */
        if (c.address || c.receiver || c.postcode || c.emailPaypal || c.emailCoupon) {
          this.jumpToStep(this.steps.tips4RegisteredOrderId);
        }
        /* if have left review and star, but have NOT claimed gift.*/
        else if (c.star || c.review) {
          this.jumpToStep(this.steps.confirmGift);
        }
        /* if have NOT left review and star. (of course Not yet choose gift.) */
        else {
          this.jumpToStep(this.steps.chooseGift);
        }

      }.bind(this)).catch(function (err) {
        console.log(err);
        this.$message.info('Some error occurred, please try again later.');
      }.bind(this)).finally(function () {
        this.ui.loading.welcome = false;
        if (this.debug.logic) {
          this.jumpToStep(this.steps.chooseGift);
        }
      }.bind(this));
    },
    saveSelectedGift: function () {
      this.ui.loading.selectGift = true;
      axiosPost(this.$apiBaseUrl + '/amzWarranty/client/amzClient/selectGift', {
        clientId: this.client.id,
        giftId: this.gifts[this.curGiftSelected].id,
      }).then(function (response) {
        // console.log(response);
        if (response.data.success) {
          this.jumpToStep(this.steps.starAndReview);
        } else {
          this.$message.info('Some error occurred, please try again later.');
        }
      }.bind(this)).catch(function (err) {
        console.log(err);
        this.$message.info('Some error occurred, please try again later.');
      }.bind(this)).finally(function () {
        this.ui.loading.selectGift = false;
        if (this.debug.logic) {
          this.jumpToStep(this.steps.starAndReview);
        }
      }.bind(this));
    },
    saveReview: function () {
      this.ui.loading.saveReview = true;
      axiosPost(this.$apiBaseUrl + '/amzWarranty/client/amzClient/saveReview', {
        clientId: this.client.id,
        country: this.client.country,
        star: this.form.stars,
        review: this.form.review,
      }).then(function (response) {
        // console.log(response);

        if (response.data.success) {
          if (this.form.stars >= this.settings.starLeastToAmazon) {
            this.jumpToStep(this.steps.guide2AmzReview);
          } else {
            this.jumpToStep(this.steps.confirmGift);
          }
        } else {
          this.$message.info('Some error occurred, please try again later.');
        }
      }.bind(this)).catch(function (err) {
        console.log(err);
        this.$message.info('Some error occurred, please try again later.');
      }.bind(this)).finally(function () {
        this.ui.loading.saveReview = false;
        if (this.debug.logic) {
          if (this.form.stars >= this.settings.starLeastToAmazon) {
            this.jumpToStep(this.steps.guide2AmzReview);
          } else {
            this.jumpToStep(this.steps.confirmGift);
          }
        }
      }.bind(this));
    },
    saveAmzReviewStatus: function (yesOrNo) {
      let saveStatus = status => {
        if (!this.client) {
          console.warn('no client info, saving status is blocked.');
          return false;
        }

        return axiosPost(this.$apiBaseUrl + '/amzWarranty/client/amzClient/saveAmzReviewStatus', {
          clientId: this.client?.id,
          amzReviewStatus: status,
        }).then(response => {
          console.log(response);
        }).finally(() => {
          this.jumpToStep(this.steps.confirmGift);
        });
      };

      if (false === yesOrNo) {
        if (this.notAmzCommentCount < 1) {
          console.log('ask again');
          this.notAmzCommentCount++;
          this.jumpToStep(this.steps.guide2AmzReview);
        } else {
          saveStatus(1);
        }
      } else {
        saveStatus(2);
      }
    },
    saveShippingAddress: function () {
      this.ui.loading.saveShippingAddress = true;
      axiosPost(this.$apiBaseUrl + '/amzWarranty/client/amzClient/saveGiftInfo', {
        clientId: this.client.id,
        giftId: this.gifts[this.curGiftSelected].id,
        receiver: this.form.name,
        address: this.form.address,
        city: this.form.city,
        region: this.form.region,
        clientCountry: this.form.country,
        postcode: this.form.postcode,
        phone: this.form.phone,
        emailCoupon: this.form.email, /* 2021-08-16 因为要求选择实物礼品也要填写邮箱，保存时借用优惠码的接收邮箱字段 */
        emailPaypal: this.form.paypal,
      }).then(function (response) {
        // console.log(response);

        if (response.data.success) {
          this.jumpToStep(this.steps.tips4Ending);
        } else {
          this.$message.info('Some error occurred, please try again later.');
        }
      }.bind(this)).catch(function (err) {
        console.log(err);
        this.$message.info('Some error occurred, please try again later.');
      }.bind(this)).finally(function () {
        this.ui.loading.saveShippingAddress = false;
        if (this.debug.logic) {
          this.jumpToStep(this.steps.tips4Ending);
        }
      }.bind(this));
    },
    saveContactUsContent: function () {
      this.ui.loading.saveContactUsContent = true;
      axiosPost(this.$apiBaseUrl + '/amzWarranty/note/amzNote/addNote', {
        bizId: this.bizId, // for test only: '1343959596087230465'
        username: this.form.name,
        email: this.form.email,
        title: this.form.contact_title,
        content: this.form.contact_content,
      }).then(response => {
        console.log(response);
        if (response.data.success) {
          this.jumpToStep(this.steps.tips4contactUsSent);
        } else {
          this.$message.info('Some error occurred, please try again later.');
        }
      }).catch(err => {
        console.log(err);
        this.$message.info('Some error occurred, please try again later.');
      }).finally(() => {
        this.ui.loading.saveContactUsContent = false;
      });
    }
  },
  created: function () {
    // this.__initGiftList_debug(); // for gift debug only

    this._initDefaultSettings();
    this._initBizId().then(this.initCustomSettings);
    this._initClipboardBtn();
  }
}
</script>

<style lang="scss" scoped>
@import './register.scss';
</style>
<style>
:root {
  --animate-duration: 1s;
}
</style>
